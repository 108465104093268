<template>
  <div class="container_index">
    <div class="top">
      <div>{{ schoolData.schoolName }}</div>
      <div style="margin-left: 190px">
        <span style="font-size: 16px">全校人数:</span> {{ schoolStudents
        }}<span style="font-size: 16px">人</span>
      </div>
      <div style="margin-left: 75px">
        <span style="font-size: 16px">学生卡数:</span> {{ schoolDevices
        }}<span style="font-size: 16px">人</span>
      </div>
      <div style="margin-left: 75px">
        <span style="font-size: 16px">在线率:</span
        ><span style="color: #5d9cff"> {{ onlineRate }}</span
        ><span style="font-size: 16px">%</span>
      </div>
      <div class="user" style="display: flex; align-items: center">
        <img
          src="../assets/ico_xiaoxi.png"
          style="margin-right: 15px"
          @click="$router.push('/noticeCenter')"
        />
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <span style="font-size: 16px">{{ schoolData.schoolManager }}</span>
            <img src="../assets/ico_down.png" style="margin-left: 15px" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <img
          src="../assets/ico_fangda.png"
          style="margin-left: 15px; transform: scale(1.2)"
          @click="full"
        />
      </div>
    </div>
    <div class="info">
      <div
        style="
          width: 5px;
          height: 40px;
          background: #a2c8fa;
          margin-right: 20px;
        "
      ></div>
      <img src="../assets/ico_xiaoxi.png" style="margin-right: 10px" />
      <div>消息：</div>
      <img
        src="../assets/but_sos.png"
        style="position: absolute; right: 40px; cursor: pointer"
        @click="$router.push('/noticeCenter/Sos')"
      />
      <div
        class="details"
        style="cursor: pointer"
        v-if="noticeInfo[0]"
      >
        <transition name="slide" mode="out-in">
          <p :key="noticeInfo[0].id" @click="noticeInfo[0].alarmType == 'sos告警' ? $router.push('/noticeCenter/Sos') : $router.push('/noticeCenter/Alarm')">
            学生<span style="color: red">{{ noticeInfo[0].studentName }}</span
            ><span
              >{{
                noticeInfo[0].alarmType == "sos告警"
                  ? "发出了SOS告警"
                  : noticeInfo[0].alarmType == "进入学校未按时签到告警"
                  ? "进入学校未按时签到"
                  : noticeInfo[0].alarmType == "进入学校围栏告警"
                  ? "进入了学校"
                  : noticeInfo[0].alarmType == "离开学校围栏告警"
                  ? "离开了学校"
                  : "未知告警"
              }},请及时关注！</span
            >
          </p></transition
        >
      </div>
    </div>
    <div class="menu">
      <div class="head">
        <img
          :src="
            schoolData.schoolPhotoUrl == ''
              ? require('../assets/school.png')
              : schoolData.schoolPhotoUrl
          "
          style="margin-left: 23px; width: 115px; height: 115px"
        />
      </div>
      <div class="item" style="background: #f5f6fa; color: #5d9cff">
        <img
          src="../assets/home.png"
          style="margin-right: 4px"
        />&nbsp;首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页
      </div>
      <div class="item" @click="$router.push('/basicManage')">
        <img
          style="margin-right: 10px"
          src="../assets/list_white.png"
        />基础管理
      </div>
      <div class="item" @click="$router.push('/teacherApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />教师申请
      </div>
      <div class="item" @click="$router.push('/studentApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />学生申请
      </div>
      <div class="item" @click="$router.push('/deviceManage')">
        <img
          style="margin-right: 10px"
          src="../assets/devices_white.png"
        />设备管理
      </div>
      <div class="item" @click="$router.push('/signManage')">
        <img
          style="margin-right: 10px"
          src="../assets/signin_white.png"
        />签到管理
      </div>
      <div class="item" @click="$router.push('/leaveManage')">
        <img
          style="margin-right: 10px"
          src="../assets/leave_white.png"
        />请假管理
      </div>
      <div class="item" @click="$router.push('/noticeManage')">
        <img
          style="margin-right: 10px"
          src="../assets/speaker_white.png"
        />学校公告
      </div>
    </div>
    <div class="content">
      <div class="left">
        <my-map class="map" width="100%" height="100%" :type="mapType"></my-map>
      </div>
      <div class="right">
        <div class="topRight">
          <el-select
            v-model="value"
            placeholder="请选择"
            class="selector"
            @change="selectOne"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="mainContainer" id="mainContainer">
            <div class="rates">
              <div id="mainLeft1">
                <div style="width: 80px; height: 50px; display: flex">
                  <div
                    style="
                      width: 30px;
                      font-size: 10px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    在校学生
                  </div>
                  <div id="small1"></div>
                </div>
                <div style="width: 80px; height: 50px; display: flex">
                  <div
                    style="
                      width: 30px;
                      font-size: 10px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    事假学生
                  </div>
                  <div id="small2"></div>
                </div>
                <div style="width: 80px; height: 50px; display: flex">
                  <div
                    style="
                      width: 30px;
                      font-size: 10px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    病假学生
                  </div>
                  <div id="small3"></div>
                </div>
                <div style="width: 80px; height: 50px; display: flex">
                  <div
                    style="
                      width: 30px;
                      font-size: 10px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    旷课学生
                  </div>
                  <div id="small4"></div>
                </div>
                <!-- <img src="../assets/ico_zaixiaoxuesheng.png" />
                <img src="../assets/ico_shijiaxuesheng.png" />
                <img src="../assets/ico_bingjiaxuesheng.png" />
                <img src="../assets/ico_kuangkexuesheng.png" /> -->
              </div>
              <div id="mainLeft2">
                <div style="width: 80px; height: 50px; display: flex">
                  <div
                    style="
                      width: 30px;
                      font-size: 10px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    无卡学生
                  </div>
                  <div id="small5"></div>
                </div>
                <!-- <img src="../assets/ico_wukaxuesheng.png" /> -->
              </div>
            </div>
            <div id="main"></div>
          </div>
        </div>
        <div class="midRight">
          <div id="main1"></div>
          <div
            class="smallTitle"
            style="position: absolute; top: 3px; left: 10px"
          >
            出勤率:
            <span style="color: #5d9cff">{{ attendanceAverageRate }}</span>
            %
          </div>
          <!-- <el-select
            v-model="value1"
            placeholder="请选择"
            class="smallSelector"
            style="position: absolute; top: 5px; right: 10px"
            @change="selectTwo"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
        <div class="bottomRight">
          <div id="main2"></div>
          <img src="../assets/legend.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myMap from "../components/map.vue";
export default {
  name: "index",
  components: {
    myMap,
  },
  props: {
    colorObj: {
      type: Object,
      default: function () {
        return {
          textStyle: "#3fc0fb",
          series: {
            color: ["#00bcd44a", "transparent"],
            colorsmall1: "#B2D0FF",
            colorsmall2: "#EBD9FF",
            colorsmall3: "#FFBDC2",
            colorsmall4: "#CEFFF7",
            colorsmall5: "#FFE2BE",
            dataColor: {
              normal: "#03a9f4",
              small1: "#5D9CFF",
              small2: "#A95DFF",
              small3: "#FF5D6A",
              small4: "#80FFEA",
              small5: "#FFB964",
              shadowsmall1: "#B2D0FF",
              shadowsmall2: "#EBD9FF",
              shadowsmall3: "#FFBDC2",
              shadowsmall4: "#CEFFF7",
              shadowsmall5: "#FFE2BE",
            },
          },
        };
      },
    },
  },
  data() {
    return {
      schoolDevices: localStorage.getItem("schoolDevices"),
      schoolStudents: localStorage.getItem("schoolStudents"),
      schoolData: JSON.parse(localStorage.getItem("schoolData")),
      noticeInfo: [],
      gradeList: [],
      gradeNameList: [],
      attendanceAverageRate: 0,
      onlineRate: 0,
      signInNum: 0,
      studentsNum: 0,
      devicesNum: 0,
      askLeaveNum: 0,
      cutSchoolNum: 0,
      teachersNum: 0,
      sickNum: 0,
      absenceNum: 0,
      noCardStudentNum: 0,
      atSchoolStudentRate: 0,
      noCardStudentRate: 0,
      absenceRate: 0,
      sickRate: 0,
      cutSchoolRate: 0,
      askLeaveRate: 0,
      schoolReport: {},
      textRoll: undefined,
      mapType: "whole",
      options: [
        {
          value: "选项1",
          label: "全部",
        },
      ],
      value: "全部",
      options1: [
        {
          value: "选项1",
          label: "周",
        },
        // {
        //   value: "选项2",
        //   label: "月",
        // },
        // {
        //   value: "选项3",
        //   label: "年",
        // },
      ],
      value1: "周",
      pie: null,
      line: null,
      lineX: ["周一", "周二", "周三", "周四", "周五"],
      lineData: [],
      //   {
      //     symbol: "none",
      //     name: "全部",
      //     data: [90, 94, 92, 96, 98],
      //     type: "line",
      //     smooth: true,
      //     itemStyle: {
      //       normal: {
      //         lineStyle: {
      //           width: 1, //设置线条粗细
      //         },
      //       },
      //     },
      //   },
      //   {
      //     symbol: "none",
      //     name: "初一",
      //     data: [98, 92, 94, 98, 92],
      //     type: "line",
      //     smooth: true,
      //     itemStyle: {
      //       normal: {
      //         lineStyle: {
      //           width: 1, //设置线条粗细
      //         },
      //       },
      //     },
      //   },
      //   {
      //     symbol: "none",
      //     name: "初二",
      //     data: [90, 92, 98, 92, 90],
      //     type: "line",
      //     smooth: true,
      //     itemStyle: {
      //       normal: {
      //         lineStyle: {
      //           width: 1, //设置线条粗细
      //         },
      //       },
      //     },
      //   },
      //   {
      //     symbol: "none",
      //     name: "初三",
      //     data: [90, 93, 91, 96, 93],
      //     type: "line",
      //     smooth: true,
      //     itemStyle: {
      //       normal: {
      //         lineStyle: {
      //           width: 1, //设置线条粗细
      //         },
      //       },
      //     },
      //   },
      //   {
      //     symbol: "none",
      //     name: "高一",
      //     data: [96, 97, 99, 92, 91],
      //     type: "line",
      //     smooth: true,
      //     itemStyle: {
      //       normal: {
      //         lineStyle: {
      //           width: 1, //设置线条粗细
      //         },
      //       },
      //     },
      //   },
      //   {
      //     symbol: "none",
      //     name: "高二",
      //     data: [93, 96, 93, 90, 97],
      //     type: "line",
      //     smooth: true,
      //     itemStyle: {
      //       normal: {
      //         lineStyle: {
      //           width: 1, //设置线条粗细
      //         },
      //       },
      //     },
      //   },
      //   {
      //     symbol: "none",
      //     name: "高三",
      //     data: [96, 91, 98, 94, 92],
      //     type: "line",
      //     smooth: true,
      //     itemStyle: {
      //       normal: {
      //         lineStyle: {
      //           width: 1, //设置线条粗细
      //         },
      //       },
      //     },
      //   },
      // ],
      pieData: [
        { value: 4800, name: "在校" },
        { value: 100, name: "无卡" },
        { value: 20, name: "事假" },
        { value: 10, name: "病假" },
        { value: 8, name: "旷课" },
      ],
    };
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.textRoll);
    next();
  },
  mounted() {
    this.$http
      .get(
        `${this.$baseUrl}/device/alarm/findByCondition?page=1&size=100&schoolId=${this.schoolData.schoolId}&userRead=0`
      )
      .then((response) => {
        console.log(response.data.result.records);
        this.noticeInfo = response.data.result.records;
        this.noticeInfoPop();
      });
    this.$http
      .post(`${this.$baseUrl}/card/info/queryDeviceOnlineCurrent`, {
        schoolId: this.schoolData.schoolId,
      })
      .then((response) => {
        this.onlineRate = response.data.result.online_nums
          ? (
              (response.data.result.online_nums /
                response.data.result.total_nums) *
              100
            ).toFixed(2)
          : "0";
      });
    this.$http
      .get(
        `${this.$baseUrl}/class/group/findGrades?page=1&size=100&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result.records);
        this.gradeList = response.data.result.records;
        this.lineData = [];
        for (let i of this.gradeList) {
          this.gradeNameList.push(i.gradeName);
          this.lineData.push({
            symbol: "none",
            name: i.gradeName,
            data: [0, 0, 0, 0, 0],
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1, //设置线条粗细
                },
              },
            },
          });
        }
        this.$http
          .get(
            `${this.$baseUrl}/sign/in/findAttendanceByWeek?schoolId=${this.schoolData.schoolId}`
          )
          .then((response) => {
            if (response.data.code != 200) {
              console.log(response.data.code);
            } else {
              console.log(response.data.result.signInWeekList);
              this.attendanceAverageRate = parseInt(
                response.data.result.attendanceAverageRate
              );
              for (let i of response.data.result.signInWeekList) {
                let target = this.lineData.find(
                  (element) => element.name == i.gradeName
                );
                console.log(target);
                if (target != undefined) {
                  target.data[i.dayOfWeek - 2] = i.attendanceRate;
                }
              }
            }
            setTimeout(() => {
              this.drawChart1();
            }, 500);
          });
      });
    this.$http
      .get(
        `${this.$baseUrl}/report/rate/findReport?schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        if (response.data.code != 200) {
          console.log(response.data.code);
          setTimeout(() => {
            this.drawChart2();
          }, 500);
        } else {
          this.schoolReport = response.data.result;
          console.log(this.schoolReport, "schoolReport");
          setTimeout(() => {
            this.drawChart2();
          }, 500);
        }
      });
    this.$http
      .get(
        `${this.$baseUrl}/class/group/findGrades?page=1&size=100&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result.records);
        for (let i of response.data.result.records) {
          this.options.push({
            value: i.gradeId,
            label: i.gradeName,
          });
        }
        console.log(this.options);
      });
    // this.drawChartLeft1();
    this.$http
      .get(
        `${this.$baseUrl}/sign/in/findReportByCondition?queryDate=${new Date()
          .toLocaleDateString()
          .replaceAll("/", "-")}&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result);
        this.signInNum = response.data.result.signInNum;
        this.studentsNum = response.data.result.studentsNum;
        this.devicesNum = response.data.result.devicesNum;
        this.askLeaveNum = response.data.result.askLeaveNum;
        this.cutSchoolNum = response.data.result.cutSchoolNum;
        this.teachersNum = response.data.result.teachersNum;
        this.sickNum = response.data.result.sickNum;
        this.absenceNum = response.data.result.absenceNum;
        this.noCardStudentNum = response.data.result.noCardStudentNum;
        this.atSchoolStudentRate = response.data.result.atSchoolStudentRate;
        this.noCardStudentRate = response.data.result.noCardStudentRate;
        this.absenceRate = response.data.result.absenceRate;
        this.sickRate = response.data.result.sickRate;
        this.cutSchoolRate = response.data.result.cutSchoolRate;
        this.askLeaveRate = response.data.result.askLeaveRate;
        this.pieData = [
          { value: this.signInNum, name: "在校" },
          { value: this.noCardStudentNum, name: "无卡" },
          { value: this.absenceNum, name: "事假" },
          { value: this.sickNum, name: "病假" },
          { value: this.cutSchoolNum, name: "旷课" },
        ];
        setTimeout(() => {
          this.drawChart();
          this.drawSmallChart("small1", this.atSchoolStudentRate.toFixed(0));
          this.drawSmallChart("small2", this.absenceRate.toFixed(0));
          this.drawSmallChart("small3", this.sickRate.toFixed(0));
          this.drawSmallChart("small4", this.cutSchoolRate.toFixed(0));
          this.drawSmallChart("small5", this.noCardStudentRate.toFixed(0));
        }, 500);
      });
    console.log(localStorage.getItem("schoolData"));
  },
  methods: {
    noticeInfoPop() {
      this.textRoll = setInterval(() => {
        let old = this.noticeInfo.shift();
        this.noticeInfo.push(old);
        console.log(this.noticeInfo);
      }, 5000);
    },
    full() {
      this.$full.toggle();
    },
    selectTwo(event) {
      console.log(event);
      // if (event == "选项1") {
      //   this.lineX = ["周一", "周二", "周三", "周四", "周五"];
      //   this.lineData = [
      //     {
      //       symbol: "none",
      //       name: "全部",
      //       data: [90, 94, 92, 96, 98],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "初一",
      //       data: [98, 92, 94, 98, 92],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "初二",
      //       data: [90, 92, 98, 92, 90],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "初三",
      //       data: [90, 93, 91, 96, 93],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "高一",
      //       data: [96, 97, 99, 92, 91],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "高二",
      //       data: [93, 96, 93, 90, 97],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "高三",
      //       data: [96, 91, 98, 94, 92],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //   ];
      // } else if (event == "选项2") {
      //   this.lineX = ["2月", "3月", "4月", "5月", "6月"];
      //   this.lineData = [
      //     {
      //       symbol: "none",
      //       name: "全部",
      //       data: [92, 90, 94, 93, 97],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "初一",
      //       data: [92, 95, 92, 99, 96],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "初二",
      //       data: [98, 92, 98, 95, 90],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "初三",
      //       data: [90, 92, 91, 93, 93],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "高一",
      //       data: [97, 97, 99, 97, 91],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "高二",
      //       data: [93, 93, 93, 98, 97],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "高三",
      //       data: [96, 91, 95, 93, 92],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //   ];
      // } else {
      //   this.lineX = ["2017", "2018", "2019", "2020", "2021"];
      //   this.lineData = [
      //     {
      //       symbol: "none",
      //       name: "一年级",
      //       data: [92, 94, 96, 94, 93],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "初一",
      //       data: [93, 92, 98, 93, 96],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "初二",
      //       data: [93, 92, 95, 98, 93],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "初三",
      //       data: [94, 96, 93, 97, 93],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "高一",
      //       data: [92, 94, 99, 99, 96],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "高二",
      //       data: [95, 90, 94, 96, 97],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //     {
      //       symbol: "none",
      //       name: "高三",
      //       data: [92, 95, 98, 91, 92],
      //       type: "line",
      //       smooth: true,
      //       itemStyle: {
      //         normal: {
      //           lineStyle: {
      //             width: 1, //设置线条粗细
      //           },
      //         },
      //       },
      //     },
      //   ];
      // }
      // this.drawChart1();
    },
    selectOne(event) {
      if (event == "选项1") {
        this.$http
          .get(
            `${
              this.$baseUrl
            }/sign/in/findReportByCondition?queryDate=${new Date()
              .toLocaleDateString()
              .replaceAll("/", "-")}&schoolId=${this.schoolData.schoolId}`
          )
          .then((response) => {
            console.log(response.data.result);
            this.signInNum = response.data.result.signInNum;
            this.studentsNum = response.data.result.studentsNum;
            this.devicesNum = response.data.result.devicesNum;
            this.askLeaveNum = response.data.result.askLeaveNum;
            this.cutSchoolNum = response.data.result.cutSchoolNum;
            this.teachersNum = response.data.result.teachersNum;
            this.sickNum = response.data.result.sickNum;
            this.absenceNum = response.data.result.absenceNum;
            this.noCardStudentNum = response.data.result.noCardStudentNum;
            this.atSchoolStudentRate = response.data.result.atSchoolStudentRate;
            this.noCardStudentRate = response.data.result.noCardStudentRate;
            this.absenceRate = response.data.result.absenceRate;
            this.sickRate = response.data.result.sickRate;
            this.cutSchoolRate = response.data.result.cutSchoolRate;
            this.askLeaveRate = response.data.result.askLeaveRate;
            this.pieData = [
              { value: this.signInNum, name: "在校" },
              { value: this.noCardStudentNum, name: "无卡" },
              { value: this.absenceNum, name: "事假" },
              { value: this.sickNum, name: "病假" },
              { value: this.cutSchoolNum, name: "旷课" },
            ];
            this.drawChart();
            this.drawSmallChart("small1", this.atSchoolStudentRate.toFixed(0));
            this.drawSmallChart("small2", this.absenceRate.toFixed(0));
            this.drawSmallChart("small3", this.sickRate.toFixed(0));
            this.drawSmallChart("small4", this.cutSchoolRate.toFixed(0));
            this.drawSmallChart("small5", this.noCardStudentRate.toFixed(0));
          });
        this.mapType = "whole";
      } else {
        this.mapType = event;
        this.$http
          .get(
            `${
              this.$baseUrl
            }/sign/in/findReportByCondition?queryDate=${new Date()
              .toLocaleDateString()
              .replaceAll("/", "-")}&schoolId=${
              this.schoolData.schoolId
            }&gradeId=${event}`
          )
          .then((response) => {
            console.log(response.data.result);
            this.signInNum = response.data.result.signInNum;
            this.studentsNum = response.data.result.studentsNum;
            this.devicesNum = response.data.result.devicesNum;
            this.askLeaveNum = response.data.result.askLeaveNum;
            this.cutSchoolNum = response.data.result.cutSchoolNum;
            this.teachersNum = response.data.result.teachersNum;
            this.sickNum = response.data.result.sickNum;
            this.absenceNum = response.data.result.absenceNum;
            this.noCardStudentNum = response.data.result.noCardStudentNum;
            this.atSchoolStudentRate = response.data.result.atSchoolStudentRate;
            this.noCardStudentRate = response.data.result.noCardStudentRate;
            this.absenceRate = response.data.result.absenceRate;
            this.sickRate = response.data.result.sickRate;
            this.cutSchoolRate = response.data.result.cutSchoolRate;
            this.askLeaveRate = response.data.result.askLeaveRate;
            this.pieData = [
              { value: this.signInNum, name: "在校" },
              { value: this.noCardStudentNum, name: "无卡" },
              { value: this.absenceNum, name: "事假" },
              { value: this.sickNum, name: "病假" },
              { value: this.cutSchoolNum, name: "旷课" },
            ];
            this.$nextTick(() => {
              this.drawChart();
              this.drawSmallChart(
                "small1",
                this.atSchoolStudentRate.toFixed(0)
              );
              this.drawSmallChart("small2", this.absenceRate.toFixed(0));
              this.drawSmallChart("small3", this.sickRate.toFixed(0));
              this.drawSmallChart("small4", this.cutSchoolRate.toFixed(0));
              this.drawSmallChart("small5", this.noCardStudentRate.toFixed(0));
            });
          });
      }
      // this.drawChart();
    },
    drawChartLeft1() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("mainLeft1"));
      let myContainer = document.getElementById("mainLeft1");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      let option = {
        title: [
          {
            text: "95%",
            x: "center",
            y: "center",
            textStyle: {
              color: "#5D9CFF",
              fontSize: 16,
            },
          },
        ],
        series: [
          {
            type: "pie",
            radius: ["75%", "80%"],
            center: ["50%", "50%"],
            hoverAnimation: false,
            color: "#B2D0FF",
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 95,
                itemStyle: {
                  normal: {
                    color: "#5D9CFF",
                    // shadowBlur: 10,
                    // shadowColor: this.colorObj.series.dataColor.shadowColor,
                  },
                },
              },
              {
                value: 100 - 95,
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "30%";
        myContainer.style.width = "50%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
    drawSmallChart(name, value) {
      let myChart = this.$echarts.init(document.getElementById(name));
      let myContainer = document.getElementById("mainContainer");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      let option = {
        title: [
          {
            text: value * 1 + "%",
            x: "center",
            y: "center",
            textStyle: {
              color: this.colorObj.series.dataColor[name],
              fontSize: 12,
            },
          },
        ],
        series: [
          {
            type: "pie",
            radius: ["75%", "80%"],
            center: ["50%", "50%"],
            hoverAnimation: false,
            color: this.colorObj.series["color" + name],
            label: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: value,
                itemStyle: {
                  normal: {
                    color: this.colorObj.series.dataColor[name],
                    shadowBlur: 0,
                    shadowColor: this.colorObj.series.dataColor.shadowColor,
                  },
                },
              },
              {
                value: 100 - value,
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "100%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main"));
      this.pie = myChart;
      let myContainer = document.getElementById("mainContainer");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      // var resizeMyChart = function () {
      //   myContainer.style.width = myContainer.scrollWidth + "px";
      //   myContainer.style.height = myContainer.clientHeight + "px";
      // };
      // 指定图表的配置项和数据
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{c}人",
        },
        grid: { top: "50%", bottom: "15%" },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "60%",
            center: ["65%", "55%"],
            minAngle: 20,
            startAngle: 0, //起始角度
            data: this.pieData,
            labelLine: {
              normal: {
                length: 18,
                length2: 10,
              },
            },
            label: {
              formatter: function (e) {
                let data = e.data;
                return `${data.name}: ${data.value}人\n\n`;
              },
              padding: [0, -12],
              //数据标签的字体配置，与其他组件相同
              textStyle: {
                fontSize: 11, //字号
                fontWeight: "normal", //粗细【normal\bold\bolder\lighter】
                fontFamily: "PingFangSC-Regular, PingFang SC", //字体【 'serif'\'monospace'\'Arial'\'Courier New'\'Microsoft YaHei'】
                color: "#666666", //颜色各异
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "100%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
    drawChart1() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main1"));
      let myContainer = document.getElementById("main1");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      // var resizeMyChart = function () {
      //   myContainer.style.width = myContainer.scrollWidth + "px";
      //   myContainer.style.height = myContainer.clientHeight + "px";
      // };
      // 指定图表的配置项和数据
      let option = {
        legend: {
          data: this.gradeNameList,
          textStyle: {
            //图例字体大小
            fontSize: 10,
            color: "#666666",
          },
          itemHeight: 5,
          itemWidth: 5,
          x: "right",
          padding: [5, 20, 0, 0],
          type: "scroll",
          width: 350,
        },
        xAxis: {
          type: "category",
          data: this.lineX,
          axisTick: {
            show: false,
          },
          boundaryGap: false,
        },
        yAxis: {
          min: 0,
          max: 100,
          type: "value",
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value}%",
          },
          show: true,
          splitLine: {
            //网格线
            show: false,
          },
        },
        grid: { top: "20%", bottom: "15%", left: "15%" },
        series: this.lineData,
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "100%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
    drawChart2() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main2"));
      let myContainer = document.getElementById("main2");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      let noCardRate = this.schoolReport.noCardRate;
      console.log(noCardRate, "123");
      // var resizeMyChart = function () {
      //   myContainer.style.width = myContainer.scrollWidth + "px";
      //   myContainer.style.height = myContainer.clientHeight + "px";
      // };
      // 指定图表的配置项和数据
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          show: false,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "持卡比例\n春/秋",
              "健康指数\n春/秋",
              "出勤指数\n春/秋",
              "学习指数\n春/秋",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              //网格线
              show: false,
            },
          },
        ],
        series: [
          {
            name: "有卡人数:秋",
            type: "bar",
            stack: "有卡",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#B9D5FA",
              },
            },

            data: [0],
          },
          {
            name: "有卡人数:春",
            type: "bar",
            stack: "有卡",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },

            itemStyle: {
              normal: {
                color: "#5D9CFF",
              },
            },
            data: [100 - parseFloat(this.schoolReport.noCardRate)],
          },
          {
            name: "无卡人数:秋",
            type: "bar",
            stack: "无卡",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#A1FFA4",
              },
            },
            data: [0],
          },
          {
            name: "无卡人数:春",
            type: "bar",
            stack: "无卡",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#49E24C",
              },
            },
            data: [parseFloat(this.schoolReport.noCardRate)],
          },
          {
            name: "健康指数:秋",
            type: "bar",
            stack: "健康",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#FFD8A9",
              },
            },

            data: [null, 0],
          },
          {
            name: "健康指数:春",
            type: "bar",
            stack: "健康",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },

            itemStyle: {
              normal: {
                color: "#FFB964",
              },
            },
            data: [null, 100 - parseFloat(this.schoolReport.sickRate)],
          },
          {
            name: "健康指数:秋",
            type: "bar",
            stack: "生病",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#BBFCFF",
              },
            },
            data: [null, 0],
          },
          {
            name: "健康指数:春",
            type: "bar",
            stack: "生病",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#64F9FF",
              },
            },
            data: [null, parseFloat(this.schoolReport.sickRate)],
          },
          {
            name: "出勤指数:秋",
            type: "bar",
            stack: "出勤",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#D6B2FF",
              },
            },

            data: [null, null, 0],
          },
          {
            name: "出勤指数:春",
            type: "bar",
            stack: "出勤",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },

            itemStyle: {
              normal: {
                color: "#A95DFF",
              },
            },
            data: [null, null, parseFloat(this.schoolReport.signInRate)],
          },
          {
            name: "出勤指数:秋",
            type: "bar",
            stack: "缺勤",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#FFC2E5",
              },
            },
            data: [null, null, 0],
          },
          {
            name: "出勤指数:春",
            type: "bar",
            stack: "缺勤",
            barWidth: 10,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#FF5DAB",
              },
            },
            data: [null, null, 100 - parseFloat(this.schoolReport.signInRate)],
          },
          // {
          //   name: "学习指数:秋",
          //   type: "bar",
          //   stack: "提升",
          //   barWidth: 10,
          //   emphasis: {
          //     focus: "series",
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: "#FFA7AE",
          //     },
          //   },

          //   data: [null, null, null, 220],
          // },
          // {
          //   name: "学习指数:春",
          //   type: "bar",
          //   stack: "提升",
          //   barWidth: 10,
          //   emphasis: {
          //     focus: "series",
          //   },

          //   itemStyle: {
          //     normal: {
          //       color: "#FF5D6A",
          //     },
          //   },
          //   data: [null, null, null, 300],
          // },
          // {
          //   name: "学习指数:秋",
          //   type: "bar",
          //   stack: "下降",
          //   barWidth: 10,
          //   emphasis: {
          //     focus: "series",
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: "#CDF3FF",
          //     },
          //   },
          //   data: [null, null, null, 623],
          // },
          // {
          //   name: "学习指数:春",
          //   type: "bar",
          //   stack: "下降",
          //   barWidth: 10,
          //   emphasis: {
          //     focus: "series",
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: "#5DD9FF",
          //     },
          //   },
          //   data: [null, null, null, 232],
          // },
          // {
          //   name: "视频广告",
          //   type: "bar",
          //   stack: "广告",
          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [150, 232, 201, 154],
          // },
          // {
          //   name: "搜索引擎",
          //   type: "bar",
          //   data: [862, 1018, 964, 1026],
          //   emphasis: {
          //     focus: "series",
          //   },
          // },
          // {
          //   name: "百度",
          //   type: "bar",
          //   barWidth: 5,
          //   stack: "搜索引擎",
          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [620, 732, 701, 734],
          // },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "80%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.item:hover {
  background-color: #85b4ff;
}

.smallTitle {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.mainContainer {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
}

.rates {
  margin-left: 20px;
  display: flex;
  position: absolute;
  width: 40%;
  float: left;
  margin-top: 10px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  height: calc(100% - 70px);
}
#main {
  width: 100%;
  float: right;
  height: calc(100% - 60px);
}

#mainLeft1 {
  display: grid;
  width: 50%;
  height: 100%;
}
#mainLeft2 {
  display: grid;
  width: 50%;
  height: 100%;
}

#small1,
#small2,
#small3,
#small4,
#small5 {
  width: calc(100% - 30px);
  height: 100%;
}

#main1 {
  width: 100%;
  height: 100%;
}

#main2 {
  width: 100%;
  height: 80%;
}

.selector {
  width: 95%;
  margin-top: 10px;
}

.smallSelector {
  width: 60px;
}

.smallSelector /deep/ .el-input__inner {
  height: 20px;
}
/* 下面设置右侧按钮居中 */
.smallSelector /deep/ .el-input__suffix {
  top: 1px;
}
.smallSelector /deep/ .el-input__icon {
  line-height: inherit;
}
.smallSelector /deep/ .el-input__suffix-inner {
  display: inline-block;
}
.user {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  position: absolute;
  right: 40px;
}

.content {
  margin-left: 160px;
  width: calc(100% - 160px);
  height: calc(100% - 180px);
  position: relative;
  top: 180px;
}

.top {
  background-color: white;
  position: absolute;
  top: 0;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 120px;
  text-align: left;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 33px;
  display: flex;
  align-items: center;
}

.info {
  background-color: white;
  position: absolute;
  top: 120px;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 60px;
  text-align: left;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  display: flex;
  align-items: center;
  background: #f5f6fa;
}

.details {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.left {
  float: left;
  height: calc(100% - 50px);
  width: calc(100% - 590px);
  margin-left: 30px;
}
.right {
  float: right;
  position: absolute;
  right: 0;
  margin-right: 40px;
  height: calc(100% - 50px);
  /* display: flex;
  flex-direction: column; */
}

.topRight {
  flex: auto;
  height: 42%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
}

.midRight {
  flex: auto;
  height: 22%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.bottomRight {
  flex: auto;
  height: 31%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
}

.container_index {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1900px;
  min-height: 920px;
  background: #f5f6fa;
}

.map {
  box-shadow: 0px 2px 4px 0px #b9d5fa;
}

.head {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
}

.menu {
  position: absolute;
  width: 160px;
  height: 100%;
  background-color: #5d9cff;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s linear;
}
.slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.slide-enter {
  transform: translateY(20px);
  opacity: 0;
}
</style>